import React from "react";
import { useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageHeader from "../components/pageHeader";
import { graphql } from "gatsby";

const Statute = ({ location }) => {
  const { statute } = useStaticQuery(
    graphql`
      query {
        statute: contentfulStatute {
          externalLink
        }
      }
    `
  );

  if (typeof window !== `undefined`)
    window.open(statute.externalLink, "_blank") ||
      window.location.replace(statute.externalLink);

  return (
    <Layout pageTitle="statute">
      <SEO pageTitle="Καταστατικό" />
      <PageHeader
        pageTitle="ΚΑΤΑΣΤΑΤΙΚΟ"
        pageDescription="Το επίσημο έγγραφο καταστατικό του οργανισμού."
        page="Καταστατικό"
        location={location}
      />
      <h1 className="message">
        If not redirected automatically,{" "}
        <a
          href={statute.externalLink}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "blue" }}
        >
          click here.
        </a>
      </h1>
    </Layout>
  );
};

export default Statute;
